<template>
<div>
    <div class="container">
        <div class="row">
            <div class="card" style="margin-top: 20px;" >
                <div class="card-header">
                    <span style="font-size: 130%;" >我的角色定义</span><br>
                    <span style="font-size: 80%;" >注：每位用户同时只能存在一个角色定义，可修改</span>
                    <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#add-prompt-button" @click="hint_prompt">
                    创建Prompt
                    </button>
                    <!-- Modal -->
                    <div class="modal fade" id="add-prompt-button" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl" >
                            <div class="modal-content">
                                <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel"> 创建Prompt</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="mb-3">
                                        <label for="add-role" class="form-label">角色名称</label>
                                        <input v-model="promptadd.role" type="text" class="form-control" id="add-role" placeholder="请输入角色名称">
                                      </div>
                                      <div class="mb-3">
                                        <label for="add-prompt" class="form-label">角色设定（Prompt）</label>
                                        <textarea v-model="promptadd.prompt" class="form-control" id="add-prompt" rows="9" placeholder="请输入prompt"></textarea>
                                      </div>
                                </div>
                                <div class="modal-footer">
                                <div class="error-message">{{ promptadd.error_message }}</div>
                                <button type="button" class="btn btn-primary" @click="add_prompt" >创建</button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>角色</th>
                                <th>创建时间</th>
                                <th>修改时间</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="prompt in prompts" :key="prompt.id" >
                                <td>{{ prompt.role }}</td>
                                <td>{{ prompt.createtime }}</td>
                                <td>{{ prompt.modifytime }}</td>
                                <td>
                                    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#update-bot-modal-'+prompt.id" >修改</button>
                                    <button type="button" class="btn btn-danger"  data-bs-toggle="modal" :data-bs-target="'#deleteModal-'+prompt.id">删除</button>
                                    <!-- 删除 -->
                                    <div class="modal fade" :id="'deleteModal-'+prompt.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="exampleModalLabel">删除提示</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                            您确认要删除嘛？
                                            </div>
                                            <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-danger" @click="remove_prompt(prompt)">删除</button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <!-- 修改 -->
                                    <div class="modal fade" :id="'update-bot-modal-'+prompt.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-xl" >
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                <h1 class="modal-title fs-5">修改Prompt</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="mb-3">
                                                        <label for="add-role" class="form-label">角色名称</label>
                                                        <input v-model="prompt.role" type="text" class="form-control" id="add-role" placeholder="请输入角色名称">
                                                      </div>
                                                      <div class="mb-3">
                                                        <label for="add-prompt" class="form-label">角色设定（Prompt）</label>
                                                        <textarea v-model="prompt.prompt" class="form-control" id="add-prompt" rows="9" placeholder="请输入prompt"></textarea>
                                                      </div>
                                                </div>
                                                <div class="modal-footer">
                                                <div class="error-message">{{ prompt.error_message }}</div>
                                                <button type="button" class="btn btn-primary" @click="update_prompt(prompt)" >保存修改</button>
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </div>
</div>
 </template>
 
 <script>
 import {ref, reactive} from 'vue'
 import $ from 'jquery'
 import {useStore} from 'vuex'
 import {Modal} from 'bootstrap/dist/js/bootstrap'
 
 
 export default {
     setup(){
        const store = useStore();
        let prompts = ref([]);

        const promptadd = reactive({
            role: "",
            prompt: "",
            error_message: "",
        })

        // const add_key = () => {
        //     $.ajax({
        //         url: "http://127.0.0.1:3000/user/key/add",
        //         type: "post",
        //         data: {
        //             xinghuoKey: "xinghuo1",
        //             huoshanKey: "huoshan1",
        //             baiduKey: "baidu1",
        //         },
        //         headers: {
        //             Authorization : "Bearer " + store.state.user.token,
        //         },
        //         success(resp){
        //             console.log(resp);
        //         },
        //         error(resp){
        //             console.log(resp);
        //         },
        //     })
        // }

        //add_key();

        const refresh_prompts = () => {
            $.ajax({
                url: "https://aiserver.arvreducation.org/user/prompt/getlist/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp)
                {
                    prompts.value = resp;
                }
            })
        }

        refresh_prompts();

        const add_prompt = () => {
            promptadd.error_message = "";
            $.ajax({
                url: "https://aiserver.arvreducation.org/user/prompt/add/",
                type: "post",
                data: {
                    role: promptadd.role,
                    prompt: promptadd.prompt,
                },
                headers: {
                    Authorization : "Bearer " + store.state.user.token,
                },
                success(resp){
                    if(resp.error_message === "success"){
                        promptadd.role = "";
                        promptadd.prompt = "";
                        Modal.getInstance("#add-prompt-button").hide();
                        refresh_prompts();
                    }
                    else{
                        promptadd.error_message = resp.error_message;
                    }
                }
            })
        }

        const hint_prompt= () => {
            promptadd.error_message = "总共可以创建5个角色，" + "还剩下"+(5 - prompts.value.length)+"个";
            
        }

        const update_prompt = (prompt) => {
            $.ajax({
                url: "https://aiserver.arvreducation.org/user/prompt/update/",
                type: "post",
                data : {
                    prompt_id : prompt.id,
                    role: prompt.role,
                    prompt: prompt.prompt,
                },
                headers: {
                    Authorization : "Bearer " + store.state.user.token,
                },
                success(resp){
                    if(resp.error_message === "success"){
                        Modal.getInstance('#update-bot-modal-'+prompt.id).hide();
                        refresh_prompts();
                    }
                    else{
                        prompt.error_message = resp.error_message;
                    }
                }
            })
        }

        const remove_prompt = (prompt) => {
            $.ajax({
                url: "https://aiserver.arvreducation.org/user/prompt/remove/",
                type: "post",
                data: {
                    prompt_id: prompt.id,
                },
                headers: {
                    Authorization : "Bearer " + store.state.user.token,
                },
                success(resp){
                    console.log(resp);
                    if(resp.error_message === "success"){
                        Modal.getInstance("#deleteModal-"+prompt.id).hide();
                        refresh_prompts();
                    }
                }
            })
        }

        return {
            prompts,
            promptadd,
            //add_key,
            add_prompt,
            remove_prompt,
            update_prompt,
            hint_prompt,
        }
       


     }
 }
 
 </script>
 
 <style scoped>
 div.error-message{
    color: red;
 }
 </style>